// NewsCard.js
import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import Slider from "react-slick";
import { collectionGroup, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const NewsCard = () => {
  const [newsMatches, setNewsMatches] = useState([]);

  useEffect(() => {
    const fetchNewsMatches = async () => {
      try {
        const matchesQuery = query(
          collectionGroup(db, "Matches"),
          where("news_title", "!=", null)
        );

        const matchesSnapshot = await getDocs(matchesQuery);
        const newsData = matchesSnapshot.docs.map((doc) => doc.data());

        setNewsMatches(newsData);
      } catch (error) {
        console.error("Error fetching news matches:", error);
      }
    };

    fetchNewsMatches();
  }, []);

  const settings = {
    dots: true,
    infinite: newsMatches.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: newsMatches.length > 1,
    autoplaySpeed: 2500,
  };

  return (
    <Card sx={{ width: "100%", height: "100%", borderRadius: "16px", bgcolor: "white" }}>
      {newsMatches.length > 0 ? (
        <Slider {...settings}>
          {newsMatches.map((match, index) => (
            <div key={index}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {match.news_title}
                </Typography>
                {match.news_subtitles && Array.isArray(match.news_subtitles) ? (
                  match.news_subtitles.map((subtitle, idx) => (
                    <div key={idx}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {subtitle.subtitle}
                      </Typography>
                      <Typography variant="body2">
                        {subtitle.text}
                      </Typography>
                    </div>
                  ))
                ) : (
                  <Typography variant="body2">
                    {match.news_subtitles}
                  </Typography>
                )}
              </CardContent>
            </div>
          ))}
        </Slider>
      ) : (
        <CardContent>
          <Typography variant="h6" gutterBottom>
            No news available
          </Typography>
        </CardContent>
      )}
    </Card>
  );
};

export default NewsCard;
