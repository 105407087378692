import React from "react";
import Seo from "../../components/Seo/Seo";

const Ratings = () => {
  return (
    <div>
      <Seo
        description={"Ratings NRL Desc"}
        name={"NRL"}
        title={"Ratings | NRL"}
        type={"Web App"}
      />
      Ratings
    </div>
  );
};

export default Ratings;
