import React from "react";
import Seo from "../../components/Seo/Seo";

const ForgetPassword = () => {
  return (
    <div>
      <Seo
        description={"ForgetPassword NRL Desc"}
        name={"NRL"}
        title={"ForgetPassword | NRL"}
        type={"Web App"}
      />
      ForgetPassword
    </div>
  );
};

export default ForgetPassword;
