// src/components/Navbar/Navbar.js
import React from "react";
import styles from "./Navbar.module.scss";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import logo from '../../Images/logo.png'; // Adjust the path to your logo

const Navbar = () => {
  return (
    <div className={styles.NavRoot}>
      <Link to={"/"}>
        <div className={styles.leftNav}>
          <img src={logo} alt="Logo" className={styles.logo} />
        </div>
      </Link>

      <div className={styles.middleNav}>
        <Link to={"/"}>
          <div>Home</div>
        </Link>
        <Link to={"/players"}>
          <div>Players</div>
        </Link>
        <Link to={"/ratings"}>
          <div>Ratings</div>
        </Link>
        <Link to={"/matchhistory"}>
          <div>Match History</div>
        </Link>
        <Link to={"/settings"}>
          <div>Settings</div>
        </Link>
        <Link to={"/help"}>
          <div>Help</div>
        </Link>
      </div>
      <div className={styles.rightNav}>
        <div className={styles.notiBtn}>
          <NotificationsActiveIcon />
        </div>
        <Link to={"/login"}>
          <button className={styles.loginBtn}>Login</button>
        </Link>
      </div>
      <div className={styles.menuIcon}>
        <MenuIcon fontSize="large" />
      </div>
    </div>
  );
};

export default Navbar;
