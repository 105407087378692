import { Stack } from "@mui/material";
import React from "react";

const HeroSection = () => {
  return (
    <Stack
      sx={{
        backgroundImage: `url(${img33})`,
        backgroundPosition: "top center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "auto",
        width: "100%",
        height: "346px",
        borderRadius: "6px",
      }}
    >
      <Stack></Stack>
    </Stack>
  );
};

export default HeroSection;
const imgg =
  "https://cdn-5c84bc36-b681cbc1.mysagestore.com/b522fd52e101edc926c3308c230445d5/contents/ckfinder/images/Category-Headers/NRL_Banner_Team_Jerseys_2024.jpg";
const img3 =
  "https://www.nrl.com/contentassets/54ab52aaca0b43b7af3eb7664e75728d/67471944_captains__juniors_nrl_finals_launch__gp0477_20229512443.jpg?center=0.652%2C0.491&preset=hero-standard-large";
const img33 =
  "https://www.onlinecreatorinstitute.com/wp-content/uploads/2020/08/chammas_20190905.jpg";
