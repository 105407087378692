// LiveCard.js
import React from "react";
import { Stack, Avatar, Typography } from "@mui/material";
import dayjs from "dayjs";

const LiveCard = ({ matches = [], width }) => {
  const getTeamImageUrl = (teamId) => {
    return `https://firebasestorage.googleapis.com/v0/b/rugby-web-app.appspot.com/o/Teams%2F${teamId}.png?alt=media`;
  };

  const now = dayjs();
  const validMatches = matches.filter((match) => match && match.start_time);
  const nextMatch = validMatches
    .filter((match) => dayjs(match.start_time).isAfter(now))
    .sort((a, b) => dayjs(a.start_time) - dayjs(b.start_time))[0] || validMatches[0];

  return (
    <Stack
      width={width}
      height={"270px"}
      borderRadius={"16px"}
      justifyContent={"space-between"}
      alignItems={"center"}
      direction={"row"}
      boxSizing={"border-box"}
      padding={"20px 80px"}
      bgcolor={"white"}
      whiteSpace={"nowrap"}
    >
      {nextMatch ? (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          width="100%"
        >
          <Stack alignItems="center" justifyContent="center">
            <Avatar
              src={getTeamImageUrl(nextMatch.home_team_id)}
              alt={nextMatch.home_team_name}
              sx={{
                width: 55,
                height: 55,
                bgcolor: "black",
                padding: "5px 5px",
              }}
            />
            <Typography fontWeight="bold" mt={1}>
              {nextMatch.home_team_name}
            </Typography>
          </Stack>
          <Stack alignItems="center" justifyContent="center">
            <Stack
              direction="row"
              gap={1}
              justifyContent="center"
              alignItems="center"
            >
              <Avatar
                sx={{
                  width: 25,
                  height: 25,
                  padding: "5px 5px",
                }}
                src="https://cdn-icons-png.flaticon.com/512/902/902997.png"
              />
              <Typography>NRL</Typography>
            </Stack>
            <Typography>PREDICTED SCORE</Typography>
            <Typography variant="h3" margin="15px 0">
              {nextMatch.predicted_home_score ?? "N/A"} : {nextMatch.predicted_away_score ?? "N/A"}
            </Typography>
            <Typography>
              <b>{dayjs(nextMatch.start_time).format("MMM DD, h:mm A")}</b>
            </Typography>
            <Typography color="#989898">
              {nextMatch.venue_name || "Unknown Venue"}
            </Typography>
          </Stack>
          <Stack alignItems="center" justifyContent="center">
            <Avatar
              src={getTeamImageUrl(nextMatch.away_team_id)}
              alt={nextMatch.away_team_name}
              sx={{
                width: 55,
                height: 55,
                bgcolor: "black",
                padding: "5px 5px",
              }}
            />
            <Typography fontWeight="bold" mt={1}>
              {nextMatch.away_team_name}
            </Typography>
          </Stack>
        </Stack>
      ) : (
        <Typography>No upcoming matches</Typography>
      )}
    </Stack>
  );
};

export default LiveCard;
