// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// Optionally, import analytics if you need it
import { getAnalytics } from "firebase/analytics";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCTG-OaT2Oe4MFHt-2jwzpjkQ_t_hn6mXw",
  authDomain: "rugby-web-app.firebaseapp.com",
  projectId: "rugby-web-app",
  storageBucket: "rugby-web-app.appspot.com",
  messagingSenderId: "509408349089",
  appId: "1:509408349089:web:5f0fda4c20007eb66219e0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Analytics if needed
if (typeof window !== "undefined") {
  const analytics = getAnalytics(app);
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const db = firebase.firestore();
const storage = firebase.storage();

export { db, storage };
