import React, { useState, useEffect } from "react";
import Seo from "../../components/Seo/Seo";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import Avatar from "@mui/material/Avatar";
import "./MatchHistory.css";

const MatchHistory = () => {
  const [matches, setMatches] = useState([]);
  const [filteredMatches, setFilteredMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [teamFilter, setTeamFilter] = useState("");
  const [dateFilter, setDateFilter] = useState("");

  useEffect(() => {
    const fetchMatches = async () => {
      try {
        const leaguesCollection = collection(db, "Leagues");
        const leaguesSnapshot = await getDocs(leaguesCollection);
        const leagueDoc = leaguesSnapshot.docs.find(doc => doc.data().class_name === "Rugby League");

        if (!leagueDoc) {
          setError("League not found");
          setLoading(false);
          return;
        }

        const leagueId = leagueDoc.id;

        const seasonsCollection = collection(db, `Leagues/${leagueId}/Seasons`);
        const seasonsSnapshot = await getDocs(seasonsCollection);

        if (seasonsSnapshot.empty) {
          setError("No seasons found for this league");
          setLoading(false);
          return;
        }

        const seasonDoc = seasonsSnapshot.docs[0];
        const seasonId = seasonDoc.id;

        const matchesCollection = collection(db, `Leagues/${leagueId}/Seasons/${seasonId}/Matches`);
        const matchesSnapshot = await getDocs(matchesCollection);

        if (matchesSnapshot.empty) {
          setError("No matches found for this season");
          setLoading(false);
          return;
        }

        const matchesData = matchesSnapshot.docs.map(doc => {
          const data = doc.data();
          const matchDate = data.match_date || data.specific_start_time || data.start_time;
          return {
            ...data,
            id: doc.id,
            home_team_score: data.home_team_score.display,
            away_team_score: data.away_team_score.display,
            match_date: matchDate
          };
        });

        setMatches(matchesData);
        setFilteredMatches(matchesData);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMatches();
  }, []);

  useEffect(() => {
    const filtered = matches.filter(match =>
      (teamFilter === "" || match.home_team_name === teamFilter || match.away_team_name === teamFilter) &&
      (dateFilter === "" || match.match_date.includes(dateFilter))
    );

    setFilteredMatches(filtered);
  }, [teamFilter, dateFilter, matches]);

  const handleTeamFilterChange = (e) => setTeamFilter(e.target.value);
  const handleDateFilterChange = (e) => setDateFilter(e.target.value);

  const teams = Array.from(new Set(matches.flatMap(match => [match.home_team_name, match.away_team_name])));

  return (
    <div className="container">
      <Seo
        description={"MatchHistory NRL Desc"}
        name={"NRL"}
        title={"MatchHistory | NRL"}
        type={"Web App"}
      />
      <h1 className="header">Match History</h1>
      {loading && <p className="loading">Loading...</p>}
      {error && <p className="error">Error: {error}</p>}
      {!loading && !error && (
        <>
          <div className="filter-container">
            <label htmlFor="teamFilter">Filter by team:</label>
            <select id="teamFilter" value={teamFilter} onChange={handleTeamFilterChange}>
              <option value="">All teams</option>
              {teams.map((team, index) => (
                <option key={index} value={team}>{team}</option>
              ))}
            </select>
            <label htmlFor="dateFilter">Filter by date:</label>
            <input id="dateFilter" type="date" value={dateFilter} onChange={handleDateFilterChange} />
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Arena</th>
                  <th>Home Team</th>
                  <th>Away Team</th>
                  <th>Score</th>
                  <th>Match Date</th>
                </tr>
              </thead>
              <tbody>
                {filteredMatches.map((match, index) => (
                  <tr key={index}>
                    <td>{match.arena_name}</td>
                    <td>
                      <Avatar src={`https://firebasestorage.googleapis.com/v0/b/rugby-web-app.appspot.com/o/Teams%2F${match.home_team_id}.png?alt=media`} />
                      {match.home_team_name}
                    </td>
                    <td>
                      <Avatar src={`https://firebasestorage.googleapis.com/v0/b/rugby-web-app.appspot.com/o/Teams%2F${match.away_team_id}.png?alt=media`} />
                      {match.away_team_name}
                    </td>
                    <td>{match.home_team_score} - {match.away_team_score}</td>
                    <td>{new Date(match.match_date).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default MatchHistory;
