import React, { useState, useEffect } from "react";
import {
  Stack,
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Collapse,
  Box,
  Typography,
  Avatar,
  Card,
  CardContent,
  IconButton,
} from "@mui/material";
import {
  SportsSoccer,
  AccessTime,
  Stadium,
  ArrowForwardIos,
  ArrowDropDown,
  BarChart,
  Sports,
} from "@mui/icons-material";
import GavelIcon from "@mui/icons-material/Gavel";
import { db } from "../../firebase";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import dayjs from "dayjs";
import HeroSection from "../../components/HeroSection/HeroSection";
import Seo from "../../components/Seo/Seo";

// Color palette for different categories
const categoryColors = {
  Possession: "#b3e5fc",
  Scoring: "#c8e6c9",
  Penalty: "#ffcdd2",
  Other: "#f5f5f5",
};

// Icons mapping for different categories
const statIcons = {
  Possession: <SportsSoccer />,
  Scoring: <BarChart />,
  Penalty: <GavelIcon />,
  Other: <Sports />,
};

const Home = () => {
  const [matches, setMatches] = useState([]);
  const [teams, setTeams] = useState([]);
  const [filteredMatches, setFilteredMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedMatchId, setSelectedMatchId] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [searchText, setSearchText] = useState("");

  // Fetch teams for filtering
  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const teamsCollection = collection(
          db,
          "Leagues",
          "2867",
          "Seasons",
          "39822",
          "Teams"
        );
        const teamsSnapshot = await getDocs(teamsCollection);
        const teamsData = teamsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTeams(teamsData);
      } catch (err) {
        console.error("Error fetching teams", err);
      }
    };
    fetchTeams();
  }, []);

  // Fetch match statistics and match names
  useEffect(() => {
    const fetchMatches = async () => {
      try {
        const statisticsCollection = collection(
          db,
          "Leagues",
          "2867",
          "2024_statistics"
        );
        const statisticsSnapshot = await getDocs(statisticsCollection);

        const matchesWithStats = await Promise.all(
          statisticsSnapshot.docs.map(async (statDoc) => {
            const matchId = statDoc.id;
            const stats = statDoc.data();
            const matchDocRef = doc(
              db,
              "Leagues",
              "2867",
              "Seasons",
              "39822",
              "Matches",
              matchId
            );
            const matchDocSnapshot = await getDoc(matchDocRef);
            const matchData = matchDocSnapshot.data();

            return {
              matchId,
              statistics: stats.statistics,
              homeTeam: matchData.home_team_name,
              awayTeam: matchData.away_team_name,
              matchDate: matchData.specific_start_time,
              arenaName: matchData.arena_name,
            };
          })
        );

        setMatches(matchesWithStats);
        setFilteredMatches(matchesWithStats);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMatches();
  }, []);

  // Handle search input
  const handleSearch = (event) => {
    setSearchText(event.target.value);
    const filtered = matches.filter(
      (match) =>
        match.homeTeam.toLowerCase().includes(event.target.value.toLowerCase()) ||
        match.awayTeam.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilteredMatches(filtered);
  };

  // Handle team filter
  const handleTeamFilter = (event) => {
    setSelectedTeam(event.target.value);
    if (event.target.value === "") {
      setFilteredMatches(matches);
      return;
    }
    const filtered = matches.filter(
      (match) =>
        match.homeTeam === event.target.value ||
        match.awayTeam === event.target.value
    );
    setFilteredMatches(filtered);
  };

  // Handle collapse toggle
  const handleCollapseClick = (matchId) => {
    setSelectedMatchId(selectedMatchId === matchId ? null : matchId);
  };

  // Render match statistics
  const renderMatchStatistics = (matchId) => {
    const statistics =
      matches.find((match) => match.matchId === matchId)?.statistics;
    if (!statistics) {
      return <Typography>Loading statistics...</Typography>;
    }

    // Group statistics by type
    const groupedStats = {};
    statistics.forEach((stat) => {
      if (!groupedStats[stat.type]) {
        groupedStats[stat.type] = [];
      }
      groupedStats[stat.type].push(stat);
    });

    return (
      <Stack spacing={2}>
        {Object.keys(groupedStats).map((type, index) => {
          const stats = groupedStats[type];
          const category = stats[0].category;
          return (
            <Card
              key={index}
              sx={{
                backgroundColor: categoryColors[category] || "#ffffff",
              }}
            >
              <CardContent>
                <Stack direction="row" alignItems="center" spacing={1} mb={2}>
                  {statIcons[category] || <Sports />}
                  <Typography variant="h6">{type}</Typography>
                </Stack>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Period</TableCell>
                      <TableCell align="center">Home Team</TableCell>
                      <TableCell align="center">Away Team</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stats.map((stat, idx) => (
                      <TableRow key={idx}>
                        <TableCell>{stat.period}</TableCell>
                        <TableCell align="center">{stat.home_team}</TableCell>
                        <TableCell align="center">{stat.away_team}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          );
        })}
      </Stack>
    );
  };

  // Render match row
  const renderMatchRow = (match) => (
    <React.Fragment key={match.matchId}>
      <TableRow>
        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar
              src={`https://firebasestorage.googleapis.com/v0/b/rugby-web-app.appspot.com/o/Teams%2F${match.homeTeam}.png?alt=media`}
            />
            <Typography>{match.homeTeam}</Typography>
          </Stack>
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar
              src={`https://firebasestorage.googleapis.com/v0/b/rugby-web-app.appspot.com/o/Teams%2F${match.awayTeam}.png?alt=media`}
            />
            <Typography>{match.awayTeam}</Typography>
          </Stack>
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center" spacing={1}>
            <AccessTime />
            <Typography>{dayjs(match.matchDate).format("DD/MMM/YYYY")}</Typography>
          </Stack>
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Stadium />
            <Typography>{match.arenaName || "Unknown Venue"}</Typography>
          </Stack>
        </TableCell>
        <TableCell>
          <IconButton
            onClick={() => handleCollapseClick(match.matchId)}
            size="small"
          >
            {selectedMatchId === match.matchId ? (
              <ArrowDropDown />
            ) : (
              <ArrowForwardIos />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse
            in={selectedMatchId === match.matchId}
            timeout="auto"
            unmountOnExit
          >
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Match Statistics
              </Typography>
              {renderMatchStatistics(match.matchId)}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );

  return (
    <Stack mt={"5rem"} spacing={4}>
      <Seo
        description={"Home NRL Desc"}
        name={"NRL"}
        title={"Home | NRL"}
        type={"Web App"}
      />
      <HeroSection />

      {/* Banner Message */}
      <Box
        sx={{
          backgroundColor: "#FFC300",
          color: "black",
          py: 2,
          textAlign: "center",
        }}
      >
        <Typography variant="h5">
          The 2024 season is over. We are maintaining our web app and will come
          back ready for the next season. See You There :)
        </Typography>
      </Box>

      {/* Search and Team Filter */}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt={5}
        mb={2}
        spacing={2}
      >
        <TextField
          label="Search by Team"
          value={searchText}
          onChange={handleSearch}
          variant="outlined"
          size="small"
          sx={{ flexGrow: 1 }}
        />
        <TextField
          select
          label="Filter by Team"
          value={selectedTeam}
          onChange={handleTeamFilter}
          variant="outlined"
          size="small"
          sx={{ flexGrow: 1 }}
        >
          <MenuItem value="">
            <em>All Teams</em>
          </MenuItem>
          {teams.map((team) => (
            <MenuItem key={team.id} value={team.name}>
              {team.name}
            </MenuItem>
          ))}
        </TextField>
      </Stack>

      {/* Match Table */}
      <Stack mt={2}>
        {loading && <Typography>Loading...</Typography>}
        {error && <Typography>Error: {error}</Typography>}
        {!loading && !error && (
          <>
            <Typography
              variant="h4"
              sx={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Matches
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Home Team</TableCell>
                  <TableCell>Away Team</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Venue</TableCell>
                  <TableCell>Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{filteredMatches.map(renderMatchRow)}</TableBody>
            </Table>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default Home;
