import React from "react";
import Seo from "../../components/Seo/Seo";

const Help = () => {
  return (
    <div>
      <Seo
        description={"Help NRL Desc"}
        name={"NRL"}
        title={"Help | NRL"}
        type={"Web App"}
      />
      Help
    </div>
  );
};

export default Help;
