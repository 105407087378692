// src/App.js
import React from "react";
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import AllRoutes from "./components/AllRoutes/AllRoutes";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import './App.css';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <Navbar />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#e8e8e8",
          }}
        >
          <div style={{ width: "90%" }}>
            <AllRoutes />
          </div>
        </div>
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
