import React from "react";
import styles from "./Footer.module.scss";
import XIcon from "@mui/icons-material/X";
import TelegramIcon from "@mui/icons-material/Telegram";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";

const Footer = () => {
  return (
    <footer className={styles.footerRoot}>
      <div className={styles.wrapperTop}>
        <div className={styles.footerLinkContainer}>
          <div className={styles.title}>
            <b>Home</b>
          </div>
          <div>How it works</div>
          <div>Partners</div>
          <div>Faq</div>
          <div>Contact</div>
        </div>
        <div className={styles.footerLinkContainer}>
          <div className={styles.title}>
            <b>NRL BETING APP</b>
          </div>
          <div>About</div>
          <div>Report</div>
        </div>
        <div className={styles.footerLinkContainer}>
          <div className={styles.title}>
            <b>Legal</b>
          </div>
          <div>Terms of Use</div>
          <div>Risk Disclosure</div>
          <div>Privacy Policy</div>
          <div>Code of Ethics</div>
        </div>
      </div>
      <div className={styles.wrapperBottom}>
        <div className={styles.logoAndSocial}>
          <div className={styles.logo}>
            <h1>
              <b>NRL BETING APP</b>
            </h1>
          </div>
          <div className={styles.social}>
            <XIcon />
            <TelegramIcon />
            <InstagramIcon />
            <FacebookIcon />
          </div>
        </div>

        <div>© 2023 NRL BETING APP. All rights reserved.</div>
        <div>
          <div>Terms of Use | Licenses | Privacy Policy</div>
          <div></div>
          <div></div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
