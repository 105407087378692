import React from "react";
import { useParams } from "react-router-dom";
import LiveCard from "../../components/LiveCard/LiveCard";
import { Stack, Typography } from "@mui/material";
import NewsCard from "../../components/NewsCard/NewsCard";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import Seo from "../../components/Seo/Seo";

const Predictions = () => {
  const { id } = useParams();
  return (
    <>
      <Seo
        description={"Predictions NRL Desc"}
        name={"NRL"}
        title={"Predictions | NRL"}
        type={"Web App"}
      />
      <Stack
        direction={"row"}
        width={"100%"}
        justifyContent={"space-around"}
        gap={2}
        mt={"5rem"}
        //   alignItems={"center"}
      >
        <Stack width={"100%"} mb={2}>
          <Stack textAlign={"left"} direction={"row"}>
            <Stack mb={1}>
              <Typography variant="h4" mt={2}>
                Team A vs Team B Prediction
              </Typography>
              <Typography variant="p" width={"70%"} mt={1}>
                Stats Insider has simulated Team A vs. Team B on Thu Apr 25,
                2024 10,000 times to provide predictions and picks,
                including best bets, betting odds and live updates.
              </Typography>
            </Stack>
            <Stack direction={"row"} alignItems={"flex-end"} gap={5} mb={1}>
              <Stack
                direction={"row"}
                gap={1}
                alignItems={"center"}
                bgcolor={"#FFFFFF"}
                p={"10px 20px"}
                border={"1px solid #989898"}
                borderRadius={"6px"}
                sx={{ cursor: "pointer" }}
              >
                <ArrowLeftIcon />
                <Stack>Prev</Stack>
              </Stack>
              <Stack
                direction={"row"}
                gap={1}
                alignItems={"center"}
                bgcolor={"#FFD829"}
                p={"10px 20px"}
                borderRadius={"6px"}
                color={"black"}
                sx={{ cursor: "pointer" }}
              >
                <Stack>Next</Stack>
                <ArrowRightIcon />
              </Stack>
            </Stack>
          </Stack>
          <LiveCard width={"100%"} />
          <Stack
            mt={2}
            borderRadius={"16px 16px 0px 0px"}
            padding={"30px 40px 60px 40px"}
            marginBottom={0.5}
            bgcolor={"white"}
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack textAlign={"left"}>
                <Stack>PRE-MATCH PROBABILITIES</Stack>
                <Stack>Last Updated: 9:06AM, Apr 22</Stack>
              </Stack>
              <Stack direction={"row"} gap={2}>
                <Stack
                  padding={"8px 16px"}
                  direction={"row"}
                  gap={1}
                  alignItems={"center"}
                  bgcolor={"#FFD829"}
                  p={"10px 20px"}
                  // border={"1px solid #989898"}
                  borderRadius={"6px"}
                  sx={{ cursor: "pointer" }}
                >
                  R2H
                </Stack>
                <Stack
                  padding={"8px 16px"}
                  direction={"row"}
                  gap={1}
                  alignItems={"center"}
                  bgcolor={"#FFFFFF"}
                  p={"10px 20px"}
                  border={"1px solid #989898"}
                  borderRadius={"6px"}
                  sx={{ cursor: "pointer" }}
                >
                  LINE
                </Stack>
                <Stack
                  padding={"8px 16px"}
                  direction={"row"}
                  gap={1}
                  alignItems={"center"}
                  bgcolor={"#FFFFFF"}
                  p={"10px 20px"}
                  border={"1px solid #989898"}
                  borderRadius={"6px"}
                  sx={{ cursor: "pointer" }}
                >
                  TOTAL
                </Stack>
              </Stack>
            </Stack>
            <Stack marginTop={5}>
              <LiveCard width={"100%"} />
            </Stack>
          </Stack>
          <Stack
            padding={"30px 40px 60px 40px"}
            bgcolor={"white"}
            borderRadius={"0px 0px 16px 16px"}
          >
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Stack>
                <Stack>BEST BETS</Stack>
              </Stack>
              <Stack direction={"row"} gap={5}>
                <Stack>ODDS</Stack>
                <Stack>PERCENTAGE</Stack>
              </Stack>
            </Stack>
            <Stack aria-label="BetTable">
              <Stack
                aria-label="BetTableHeader"
                direction={"row"}
                justifyContent={"space-between"}
              >
                <Stack fontWeight={"bold"}>TYPE</Stack>
                <Stack fontWeight={"bold"}>BET</Stack>
                <Stack fontWeight={"bold"}>MARKET ODDS</Stack>
                <Stack fontWeight={"bold"}> SI ODDS</Stack>
                <Stack fontWeight={"bold"}>EDGE</Stack>
              </Stack>
              <Stack gap={1} mt={3} aria-label="BetTableData">
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Stack>H2H </Stack>
                  <Stack>New England </Stack>
                  <Stack>1.26</Stack>
                  <Stack>1.21</Stack>
                  <Stack>3%🔥</Stack>
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Stack>H2H </Stack>
                  <Stack>New England </Stack>
                  <Stack>1.26</Stack>
                  <Stack>1.21</Stack>
                  <Stack>3%🔥</Stack>
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Stack>H2H </Stack>
                  <Stack>New England </Stack>
                  <Stack>1.26</Stack>
                  <Stack>1.21</Stack>
                  <Stack>3%🔥</Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack mt={14.5}>
          <NewsCard />
          <NewsCard />
          <NewsCard />
        </Stack>
      </Stack>
    </>
  );
};

export default Predictions;
