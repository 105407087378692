import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../../pages/Home/Home";
import Signup from "../../pages/Signup/Signup";
import Login from "../../pages/Login/Login";
import ForgetPassword from "../../pages/ForgetPassword/ForgetPassword";
import Players from "../../pages/Players/Players";
import Help from "../../pages/Help/Help";
import Settings from "../../pages/Settings/Settings";
import Ratings from "../../pages/Ratings/Ratings";
import MatchHistory from "../../pages/MatchHistory/MatchHistory";
import Predictions from "../../pages/Predictions/Predictions";
import ScrollToTopOnMount from "../ScrollToTopOnMount/ScrollToTopOnMount";

const AllRoutes = () => {
  return (
    <>
      <ScrollToTopOnMount />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/players" element={<Players />} />
        <Route path="/help" element={<Help />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/ratings" element={<Ratings />} />
        <Route path="/predictions" element={<Predictions />} />
        <Route path="/predictions/:id" element={<Predictions />} />
        <Route path="/matchhistory" element={<MatchHistory />} />
        <Route path="/recoveraccount" element={<ForgetPassword />} />
      </Routes>
    </>
  );
};

export default AllRoutes;
