import React, { useState, useEffect } from "react";
import Seo from "../../components/Seo/Seo";
import { db } from "../../firebase"; // Import the db from firebase.js
import { collection, getDocs } from "firebase/firestore"; // Import Firestore methods
import "./Players.css"; // Import the CSS file

const Players = () => {
  const [players, setPlayers] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]); // Initialize state for filtered players
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({ team: "", height: "" });

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const leaguesCollection = collection(db, "Leagues");
        const leaguesSnapshot = await getDocs(leaguesCollection);
        const leagueId = leaguesSnapshot.docs.find(doc => doc.data().class_name === "Rugby League")?.id;

        if (leagueId) {
          const seasonsCollection = collection(db, `Leagues/${leagueId}/Seasons`);
          const seasonsSnapshot = await getDocs(seasonsCollection);
          const seasonId = seasonsSnapshot.docs[0]?.id;

          if (seasonId) {
            const teamsCollection = collection(db, `Leagues/${leagueId}/Seasons/${seasonId}/Teams`);
            const teamsSnapshot = await getDocs(teamsCollection);

            const playersData = [];
            for (const teamDoc of teamsSnapshot.docs) {
              const teamData = teamDoc.data();
              const playersCollection = collection(db, `Leagues/${leagueId}/Seasons/${seasonId}/Teams/${teamDoc.id}/Players`);
              const playersSnapshot = await getDocs(playersCollection);
              
              playersSnapshot.forEach(playerDoc => {
                playersData.push({
                  ...playerDoc.data(),
                  team_name: teamData.team_name,
                });
              });
            }
            setPlayers(playersData);
            setFilteredPlayers(playersData); // Set filtered players initially to all players
          }
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPlayers();
  }, []);

  useEffect(() => {
    let updatedPlayers = players;

    // Apply search filter
    if (search) {
      updatedPlayers = updatedPlayers.filter((player) =>
        player.name.toLowerCase().includes(search.toLowerCase())
      );
    }

    // Apply team filter
    if (filters.team) {
      updatedPlayers = updatedPlayers.filter(
        (player) => player.team_name === filters.team
      );
    }

    // Apply height filter
    if (filters.height) {
      updatedPlayers = updatedPlayers.filter(
        (player) => player.player_height === parseInt(filters.height)
      );
    }

    setFilteredPlayers(updatedPlayers);
  }, [search, filters, players]);

  const handleSearchChange = (e) => setSearch(e.target.value);

  const handleFilterChange = (e) =>
    setFilters({ ...filters, [e.target.name]: e.target.value });

  const uniqueTeams = Array.from(new Set(players.map(player => player.team_name)));
  const uniqueHeights = Array.from(new Set(players.map(player => player.player_height)));

  return (
    <div className="container">
      <Seo
        description={"Players NRL Desc"}
        name={"NRL"}
        title={"Players | NRL"}
        type={"Web App"}
      />
      <h1 className="header">Players</h1>
      <div className="search-filter-container">
        <input
          type="text"
          placeholder="Search by name..."
          value={search}
          onChange={handleSearchChange}
          className="search-bar"
        />
        <select name="team" value={filters.team} onChange={handleFilterChange}>
          <option value="">All Teams</option>
          {uniqueTeams.map((team, index) => (
            <option key={index} value={team}>{team}</option>
          ))}
        </select>
        <select
          name="height"
          value={filters.height}
          onChange={handleFilterChange}
        >
          <option value="">All Heights</option>
          {uniqueHeights.map((height, index) => (
            <option key={index} value={height}>
              {height}
            </option>
          ))}
        </select>
      </div>
      {loading && <p className="loading">Loading...</p>}
      {error && <p className="error">Error: {error}</p>}
      {!loading && !error && (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Full Name</th>
                <th>Nickname</th>
                <th>Position</th>
                <th>Height</th>
                <th>Jersey Number</th>
                <th>Team</th>
              </tr>
            </thead>
            <tbody>
              {filteredPlayers.map((player, index) => (
                <tr key={index}>
                  <td>{player.name}</td>
                  <td>{player.short_name}</td>
                  <td>{player.position}</td>
                  <td>{player.player_height}</td>
                  <td>{player.player_jersey_number}</td>
                  <td>{player.team_name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Players;
