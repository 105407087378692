import React from "react";
import Seo from "../../components/Seo/Seo";

const Settings = () => {
  return (
    <div>
      <Seo
        description={"Settings NRL Desc"}
        name={"NRL"}
        title={"Settings | NRL"}
        type={"Web App"}
      />
      Settings
    </div>
  );
};

export default Settings;
